import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Stack, Drawer } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { NAVBAR } from '../../../config';
// components
import Logo from '../../../components/Logo';
import Scrollbar from '../../../components/Scrollbar';
import { NavSectionVertical } from '../../../components/nav-section';

// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
// import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';
//

import NavbarDocs from './NavbarDocs';
import NavbarAccount from './NavbarAccount';
import CollapseButton from './CollapseButton';
import useAuth from '../../../hooks/useAuth';


// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

NavbarVertical.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function NavbarVertical({ isOpenSidebar, onCloseSidebar }) {
  const theme = useTheme();
  const {user} = useAuth();
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();
    const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;
    const ICONS = {
      user: getIcon('ic_user'),
      transaction: getIcon('transaction'),
      buy_cable: getIcon('adex_cable'),
      calendar: getIcon('ic_calendar'),
      buy_data: getIcon('adex_data'),
      buy_airtime: getIcon('adex_phone'),
      dashboard: getIcon('adex_home'),
      fund: getIcon('fund'),
      phone: getIcon('phone'), 
      stock: getIcon('stock'),
      setting: getIcon('setting'),
      wallet: getIcon('wallet'),
      cal: getIcon('cal'),
      price: getIcon('price')
    };


      // nav bar  
     const  navConfig =  [
   {
  subheader: 'general',
  items: [
    { title: 'dashboard', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
    { title: 'buy data', path: PATH_DASHBOARD.general.buydata, icon: ICONS.buy_data },
    { title: 'buy airtime', path: PATH_DASHBOARD.general.buyairtime, icon: ICONS.buy_airtime },
    { title: 'cable sub', path: PATH_DASHBOARD.general.buycable, icon: ICONS.buy_cable },
    
    {title: 'fund wallet', 
    path: PATH_DASHBOARD.fund.root, 
    icon:ICONS.fund,
    children:[
     {title: 'Automated Bank Transfer', path: PATH_DASHBOARD.fund.account},
     {title: 'ATM Transfer', path: PATH_DASHBOARD.fund.atm},
     {title: 'Manual Funding', path: PATH_DASHBOARD.fund.manual},
     {title: 'paystack funding', path: PATH_DASHBOARD.fund.paystack}
     ]
    },
    {
      title: 'transaction calculator', icon: ICONS.cal, path: PATH_DASHBOARD.general.calculator
    },
    {title: 'pricing', icon: ICONS.price, path: PATH_DASHBOARD.general.pricing}
     ],
},
{
  subheader: 'management',
  items: [
    // MANAGEMENT : USER
    {
      title: 'Transaction',
      icon: ICONS.transaction,
      path: PATH_DASHBOARD.trans.root,
      children: [
        { title: 'Transaction Summary', path: PATH_DASHBOARD.trans.history },
        {title: 'Deposit Summary', path: PATH_DASHBOARD.trans.deposit},
        {title: 'manual funding', path: PATH_DASHBOARD.trans.manualfunding},
        { title: 'Data Transaction', path: PATH_DASHBOARD.trans.data },
        {title: 'Airtime Transaction', path: PATH_DASHBOARD.trans.airtime},
        {title: 'Cable Transaction', path: PATH_DASHBOARD.trans.cable},
        {title: 'Electricity Transaction', path: PATH_DASHBOARD.trans.bill},
        {title: 'Bulk SMS Transaction', path: PATH_DASHBOARD.trans.bulksms},
        {title: 'Stock Summary', path: PATH_DASHBOARD.trans.stock},
        {title: 'Airtime 2 Cash', path: PATH_DASHBOARD.trans.airtimecash},
        {title: 'Result Checker', path: PATH_DASHBOARD.trans.result}
   
      ],
    },
    {title: 'setting', path: PATH_DASHBOARD.user.account, icon: ICONS.setting},
    {title: 'APP', path: PATH_DASHBOARD.general.device, icon:ICONS.phone},
    {title: 'stock wallet', path: PATH_DASHBOARD.general.stock, icon: ICONS.wallet}
  ],
},
 ]

 const customerTrans = [
  {
    subheader: 'customer care',
    items: [
      // MANAGEMENT : USER
      {
        title: 'Transaction',
        icon: ICONS.transaction,
        path: PATH_DASHBOARD.customer.root,
        children: [
          { title: 'Transaction Summary', path: PATH_DASHBOARD.customer.history },
          {title: 'Deposit Summary', path: PATH_DASHBOARD.customer.deposit},
          {title: 'manual funding', path: PATH_DASHBOARD.customer.manualfunding},
          { title: 'Data Transaction', path: PATH_DASHBOARD.customer.data },
          {title: 'Airtime Transaction', path: PATH_DASHBOARD.customer.airtime},
          {title: 'Cable Transaction', path: PATH_DASHBOARD.customer.cable},
          {title: 'Electricity Transaction', path: PATH_DASHBOARD.customer.bill},
          {title: 'Bulk SMS Transaction', path: PATH_DASHBOARD.customer.bulksms},
          {title: 'Stock Summary', path: PATH_DASHBOARD.customer.stock},
          {title: 'Airtime 2 Cash', path: PATH_DASHBOARD.customer.airtimecash},
          {title: 'Result Checker', path: PATH_DASHBOARD.customer.result}
        ],
      },
      {path: PATH_DASHBOARD.credituser, title: 'credit / Debit', icon: ICONS.wallet}
    ],
  },
 ];

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: 'center' }),
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Logo />

          {isDesktop && !isCollapse && (
            <CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
          )}
        </Stack>

        <NavbarAccount isCollapse={isCollapse} />
      </Stack>
        <NavSectionVertical navConfig={navConfig} isCollapse={isCollapse} />
     {user?.type === 'CUSTOMER' &&   <NavSectionVertical navConfig={customerTrans} isCollapse={isCollapse} />}
     {user?.type === 'ADMIN' &&   <NavSectionVertical navConfig={customerTrans} isCollapse={isCollapse} />}
      <Box sx={{ flexGrow: 1 }} />
      {!isCollapse && <NavbarDocs />}
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      {!isDesktop && (
        <Drawer open={isOpenSidebar} onClose={onCloseSidebar} PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}>
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: 'dashed',
              bgcolor: 'background.default',
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}

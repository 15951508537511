import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 3264.000000 3264.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,3264.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M24840 19949 c-175 -10 -372 -32 -535 -60 -66 -11 -133 -22 -150 -25
-62 -10 -65 -12 -65 -62 -3 -211 0 -895 3 -898 2 -2 45 8 97 21 51 14 132 32
179 40 47 9 93 17 101 20 8 2 83 12 165 22 185 22 699 25 865 4 58 -7 118 -14
133 -16 16 -2 40 -6 55 -9 15 -3 44 -8 64 -11 80 -9 406 -73 420 -81 3 -2 50
-16 104 -29 138 -36 287 -84 454 -146 47 -17 99 -36 115 -41 17 -6 35 -15 41
-19 6 -5 29 -15 51 -23 122 -43 455 -211 693 -351 191 -112 699 -468 728 -511
4 -6 25 -23 47 -39 73 -53 446 -418 547 -535 235 -272 415 -520 549 -755 18
-33 42 -73 51 -90 45 -77 169 -331 207 -425 24 -58 49 -118 56 -135 48 -111
131 -412 171 -617 10 -52 22 -88 29 -88 6 0 104 36 216 81 433 171 656 261
667 268 12 7 -23 165 -78 346 -12 39 -25 84 -29 100 -12 45 -113 313 -174 460
-196 472 -517 1004 -842 1395 -81 97 -229 264 -275 311 -14 14 -64 66 -111
115 -89 92 -226 219 -339 314 -36 30 -69 60 -75 66 -5 5 -32 28 -60 49 -27 21
-58 47 -67 56 -10 10 -39 32 -65 50 -27 18 -75 53 -108 79 -83 65 -509 341
-630 408 -133 74 -415 217 -490 248 -33 14 -78 34 -100 44 -38 18 -155 65
-240 97 -22 8 -56 21 -75 29 -60 23 -214 75 -233 79 -10 2 -55 15 -100 29 -45
14 -118 35 -162 47 -44 11 -91 25 -105 30 -68 23 -498 104 -630 119 -52 6
-108 13 -125 15 -16 2 -66 7 -110 10 -44 4 -93 9 -110 11 -65 9 -588 11 -725
3z"/>
<path d="M7358 18735 c-2 -1 -63 -6 -136 -10 -74 -4 -146 -8 -160 -10 -15 -2
-56 -6 -92 -9 -122 -11 -175 -17 -197 -21 -12 -3 -41 -7 -65 -10 -41 -5 -99
-15 -213 -35 -65 -11 -161 -34 -205 -48 -19 -6 -102 -32 -185 -58 -82 -26
-157 -51 -165 -55 -25 -13 -55 -22 -62 -21 -5 1 -8 -2 -8 -7 0 -11 -39 -26
-58 -22 -7 1 -12 -2 -11 -6 3 -20 -2 -25 -16 -13 -15 13 -39 5 -30 -11 4 -5 1
-10 -4 -10 -21 -3 -26 -4 -39 -13 -7 -4 -16 -5 -19 -2 -3 3 -19 -6 -35 -19
-16 -14 -34 -23 -39 -19 -5 3 -9 0 -9 -5 0 -6 -6 -11 -14 -11 -8 0 -16 -5 -18
-10 -2 -6 -13 -12 -25 -13 -13 0 -25 -7 -27 -14 -3 -7 -17 -13 -31 -13 -18 0
-25 -5 -25 -19 0 -12 -9 -21 -25 -25 -14 -4 -23 -11 -19 -17 3 -5 -8 -15 -25
-22 -19 -7 -31 -18 -31 -29 0 -12 -8 -18 -25 -18 -20 0 -24 -5 -22 -22 1 -17
-3 -23 -18 -23 -13 0 -21 -8 -23 -22 -2 -15 -10 -23 -23 -23 -14 0 -19 -7 -19
-25 0 -18 -5 -25 -19 -25 -14 0 -24 -12 -34 -42 -8 -24 -19 -42 -26 -41 -6 2
-14 -9 -17 -25 -4 -15 -11 -32 -15 -38 -20 -25 -79 -150 -79 -166 0 -9 -9 -34
-19 -55 -11 -21 -22 -63 -26 -93 -4 -30 -14 -68 -23 -85 -22 -43 -35 -348 -23
-544 7 -113 15 -172 30 -213 12 -32 21 -68 21 -80 0 -13 9 -38 20 -56 11 -18
20 -38 20 -44 0 -19 61 -139 109 -213 42 -66 169 -228 197 -250 7 -6 36 -32
66 -57 45 -40 126 -104 203 -163 11 -8 33 -25 49 -37 33 -25 74 -49 126 -73
19 -10 42 -23 50 -30 8 -7 26 -16 40 -20 14 -4 36 -16 49 -26 13 -11 31 -19
39 -19 8 0 40 -12 71 -26 106 -50 136 -62 173 -74 21 -6 40 -15 43 -20 3 -4
29 -14 57 -21 29 -6 62 -18 73 -25 11 -7 38 -16 60 -20 22 -3 58 -15 80 -24
22 -10 58 -21 80 -25 22 -4 53 -14 68 -22 16 -8 56 -19 89 -24 33 -5 73 -16
88 -24 15 -8 54 -18 86 -24 33 -5 75 -16 94 -24 19 -8 64 -18 100 -22 36 -4
80 -13 98 -21 19 -7 71 -18 117 -24 46 -6 98 -17 117 -24 18 -8 65 -17 103
-21 39 -4 88 -14 110 -22 22 -8 75 -18 117 -24 42 -5 95 -16 118 -24 23 -8 76
-19 118 -24 42 -6 93 -16 112 -24 19 -8 64 -18 100 -22 35 -4 83 -14 105 -22
22 -8 76 -19 119 -24 43 -5 91 -16 105 -24 14 -7 49 -16 76 -20 28 -4 68 -15
90 -25 22 -10 61 -21 87 -24 25 -4 52 -14 58 -21 7 -8 20 -15 29 -15 9 0 43
-14 76 -30 33 -16 65 -30 70 -30 22 0 122 -79 161 -127 41 -50 65 -127 48
-154 -9 -14 -14 -63 -17 -146 -2 -58 -4 -63 -24 -63 -18 0 -21 -5 -20 -27 2
-23 -2 -28 -21 -27 -17 0 -21 -3 -16 -18 4 -13 -3 -25 -20 -39 -14 -11 -26
-26 -26 -33 0 -22 -98 -67 -193 -89 -89 -20 -122 -21 -887 -23 -437 -1 -833 2
-880 7 -47 5 -107 11 -133 14 -73 8 -162 31 -204 54 -10 5 -32 15 -50 22 -17
7 -30 16 -27 20 2 4 -5 10 -16 14 -11 3 -20 13 -20 21 0 8 -7 14 -15 14 -8 0
-15 5 -15 10 0 6 -11 16 -25 22 -14 6 -25 19 -25 28 0 10 -10 20 -21 23 -18 5
-20 11 -15 33 6 24 5 26 -18 20 -32 -8 -38 2 -25 47 9 33 18 42 41 38 4 0 47
9 95 20 48 12 98 23 110 25 12 2 71 16 130 30 60 14 119 28 133 30 14 3 61 14
105 24 44 11 190 44 325 75 375 85 420 97 420 106 0 5 -4 9 -8 9 -10 0 -247
-53 -782 -175 -8 -2 -107 -24 -220 -49 -113 -26 -233 -53 -268 -62 -34 -8 -67
-12 -73 -9 -6 4 -19 -1 -29 -10 -13 -11 -20 -13 -25 -5 -10 16 24 50 49 49 20
-1 174 31 311 64 29 7 266 58 295 63 14 3 34 8 45 11 11 4 27 8 35 9 19 4 82
16 95 20 16 4 158 35 184 40 12 3 53 12 90 20 80 19 72 18 146 33 81 17 125
31 125 41 0 32 -60 28 -240 -15 -8 -2 -91 -20 -185 -40 -176 -37 -349 -74
-375 -80 -49 -11 -369 -78 -390 -81 -14 -3 -34 -8 -45 -11 -27 -9 -22 -8 -90
-17 -33 -5 -65 -13 -72 -18 -16 -13 -27 10 -13 27 7 8 25 15 40 15 15 0 112
18 216 41 104 22 198 43 209 45 11 2 31 6 45 9 14 3 34 7 45 9 11 2 99 21 195
41 96 20 261 55 365 77 105 21 197 41 205 43 8 2 23 4 33 4 24 1 22 31 -3 31
-11 0 -75 -12 -142 -26 -68 -14 -132 -28 -143 -30 -11 -2 -31 -6 -45 -9 -14
-3 -36 -7 -50 -10 -14 -3 -36 -7 -50 -10 -14 -3 -34 -7 -45 -9 -329 -68 -473
-98 -485 -101 -28 -7 -255 -53 -285 -58 -16 -3 -46 -8 -66 -12 -23 -4 -39 -3
-43 4 -11 18 -26 13 -26 -9 0 -11 -7 -20 -15 -20 -17 0 -18 2 -6 53 5 22 5 39
0 43 -15 9 -39 -15 -32 -32 3 -8 1 -14 -5 -14 -15 0 -24 61 -11 76 9 11 8 14
-3 14 -8 0 -18 -4 -24 -9 -5 -6 -25 -6 -44 -2 -26 5 -34 12 -32 25 3 14 -1 17
-13 13 -8 -4 -35 0 -58 9 -23 8 -49 16 -57 16 -8 0 -45 9 -81 19 -36 10 -108
23 -160 28 -134 13 -486 5 -584 -14 -44 -8 -91 -17 -105 -19 -14 -2 -45 -9
-70 -16 -25 -7 -57 -12 -72 -11 -21 0 -28 -5 -28 -19 0 -12 -8 -20 -22 -22
-15 -2 -24 -11 -26 -24 -2 -12 -11 -24 -21 -27 -12 -3 -21 -18 -25 -40 -4 -20
-13 -40 -21 -45 -41 -22 -37 -406 5 -497 11 -24 20 -52 20 -62 0 -10 11 -39
25 -65 14 -26 25 -53 25 -61 0 -8 7 -20 16 -27 8 -8 21 -28 29 -46 43 -104
260 -345 408 -453 45 -33 89 -67 97 -75 8 -8 27 -20 43 -27 15 -7 27 -16 27
-20 0 -5 17 -18 38 -29 20 -11 55 -32 77 -45 38 -23 58 -33 108 -55 12 -5 29
-14 37 -19 35 -20 127 -59 155 -66 17 -3 37 -13 45 -20 8 -7 40 -20 70 -28 30
-8 69 -19 85 -25 85 -30 195 -61 238 -67 27 -3 67 -13 89 -21 23 -8 89 -18
147 -24 58 -5 131 -15 161 -22 78 -18 1801 -19 1907 -1 39 7 144 17 232 23 88
5 186 16 218 24 32 8 87 18 123 21 36 4 92 15 125 25 33 10 80 21 105 24 25 4
70 15 100 25 30 10 73 23 95 29 22 6 72 22 110 37 39 15 93 36 120 46 143 52
352 177 560 335 66 50 195 147 286 216 306 230 400 316 475 434 19 30 39 54
44 54 6 0 10 -17 10 -37 0 -21 9 -57 19 -79 10 -23 22 -56 26 -74 6 -29 22
-62 94 -195 51 -96 208 -272 326 -368 39 -32 89 -74 113 -95 24 -20 54 -41 66
-47 13 -5 31 -16 40 -24 24 -20 79 -50 146 -80 14 -6 32 -15 40 -20 33 -20
123 -61 133 -61 7 0 35 -12 62 -25 28 -14 58 -25 68 -25 10 0 34 -8 55 -19 20
-10 55 -21 77 -25 22 -4 63 -15 90 -25 28 -10 73 -21 102 -25 29 -4 70 -13 93
-21 22 -8 96 -19 164 -25 67 -6 138 -16 157 -22 24 -9 246 -13 788 -16 645 -3
774 -1 890 13 75 9 192 20 261 26 69 5 145 16 170 24 25 8 77 19 115 24 39 5
88 16 110 25 22 9 60 20 85 25 57 13 175 55 192 69 7 6 27 14 43 18 28 6 111
44 145 65 8 5 33 18 55 30 69 35 108 58 114 66 3 5 31 25 61 46 30 20 76 56
101 80 26 24 60 54 75 68 37 31 138 151 172 203 15 23 29 43 32 46 16 16 76
127 132 243 4 9 12 25 17 35 30 59 98 279 110 352 5 30 16 84 24 120 16 65 28
180 48 450 6 92 9 503 6 1125 -3 830 -6 998 -20 1095 -9 63 -16 143 -17 178 0
40 -7 79 -19 105 -10 23 -22 71 -26 107 -4 36 -16 81 -26 100 -10 19 -21 52
-24 72 -3 21 -12 43 -19 51 -8 7 -17 29 -21 48 -12 62 -15 67 -34 62 -15 -4
-18 0 -14 19 6 34 -13 79 -31 72 -10 -4 -14 0 -14 13 3 39 -12 73 -32 73 -14
0 -20 7 -20 24 0 32 -10 56 -22 56 -6 0 -8 -7 -4 -15 3 -8 1 -15 -4 -15 -5 0
-12 20 -16 45 -6 41 -8 44 -31 39 -22 -6 -24 -4 -21 22 3 26 0 29 -25 26 -25
-3 -27 -1 -21 23 6 24 4 26 -22 23 -19 -2 -28 1 -26 10 1 6 3 18 5 25 1 8 -8
11 -27 9 -26 -3 -28 -1 -22 24 6 24 5 26 -14 21 -36 -9 -52 1 -39 25 10 18 9
20 -8 16 -11 -3 -28 -1 -37 4 -14 8 -15 13 -5 24 6 8 9 18 5 21 -4 4 -10 2
-14 -4 -7 -11 -54 -11 -84 2 -10 4 -14 13 -11 22 4 10 2 14 -4 10 -6 -4 -24 0
-39 8 -16 8 -35 15 -42 15 -16 0 -54 21 -67 38 -6 8 -23 15 -38 15 -15 1 -27
5 -27 8 0 3 -25 12 -55 19 -30 7 -55 17 -55 21 0 4 -24 15 -52 24 -29 9 -62
20 -73 25 -20 9 -109 35 -175 51 -66 17 -331 68 -385 75 -152 20 -211 26 -284
31 -125 8 -1582 7 -1676 -1 -73 -6 -274 -32 -335 -42 -14 -3 -38 -6 -55 -8
-48 -5 -108 -17 -130 -26 -19 -8 -129 -33 -150 -34 -25 -2 -287 -92 -310 -107
-5 -3 -31 -12 -57 -20 -27 -7 -48 -16 -48 -20 0 -4 -9 -5 -20 -2 -11 3 -20 0
-20 -6 0 -6 -11 -11 -25 -11 -16 0 -25 -6 -25 -15 0 -8 -9 -15 -20 -15 -23 0
-60 -19 -76 -39 -9 -12 -38 -20 -86 -24 -10 -1 -17 -8 -15 -17 1 -10 -7 -16
-25 -18 -20 -2 -28 -9 -28 -23 0 -10 -7 -19 -15 -19 -26 0 -63 -22 -68 -41 -3
-11 -14 -19 -24 -19 -13 0 -24 -12 -33 -34 -7 -19 -22 -37 -32 -40 -11 -2 -22
-13 -25 -24 -3 -11 -13 -22 -23 -25 -13 -3 -17 -11 -13 -25 4 -16 -1 -21 -19
-24 -15 -2 -24 -11 -26 -25 -2 -13 -10 -23 -18 -23 -8 0 -19 -18 -26 -44 -7
-27 -19 -46 -29 -49 -11 -3 -19 -19 -24 -46 -4 -24 -12 -41 -20 -41 -7 0 -16
-15 -20 -32 -4 -18 -13 -43 -21 -55 -7 -11 -18 -43 -25 -70 -6 -26 -17 -57
-24 -68 -6 -11 -15 -32 -19 -47 -10 -41 -13 -40 -191 43 -27 12 -52 23 -55 24
-3 1 -32 14 -65 29 -33 16 -65 29 -71 30 -7 1 -18 7 -25 12 -12 9 -36 21 -59
29 -23 8 -47 20 -58 29 -8 5 -23 12 -35 13 -25 4 -59 32 -51 44 2 5 20 -1 39
-11 20 -11 36 -19 38 -19 6 1 58 -22 135 -59 45 -22 82 -38 82 -35 0 3 9 -2
21 -13 12 -10 22 -17 23 -15 1 4 58 -21 164 -71 61 -29 82 -32 67 -8 -8 12
-272 143 -279 138 -2 -2 -14 6 -26 17 -12 11 -23 19 -24 17 -3 -6 -66 20 -66
27 0 4 -6 8 -12 8 -7 0 -47 17 -89 37 -42 20 -82 36 -87 36 -6 0 -16 9 -22 19
-13 25 -13 34 0 26 16 -10 11 1 -7 16 -11 10 -14 10 -9 2 4 -8 2 -13 -7 -13
-8 0 -17 -9 -20 -20 -3 -11 -11 -20 -17 -20 -8 0 -10 8 -5 23 3 12 8 33 9 47
2 15 8 24 16 22 11 -3 11 0 1 12 -12 14 -16 14 -34 1 -12 -9 -22 -23 -22 -32
0 -9 -5 -18 -11 -20 -13 -4 -12 39 1 69 11 28 -3 40 -30 22 -28 -18 -41 -4
-23 25 17 27 4 37 -27 21 -31 -16 -44 -6 -26 21 15 26 6 37 -18 22 -27 -17
-46 -1 -30 25 16 25 6 40 -16 22 -8 -7 -22 -10 -30 -6 -13 5 -14 10 -5 27 15
26 7 34 -17 18 -17 -10 -28 -5 -81 31 -34 23 -62 47 -62 53 0 7 -5 6 -10 -3
-9 -13 -12 -13 -21 3 -5 9 -17 17 -24 17 -8 0 -15 5 -15 10 0 6 -7 10 -15 10
-9 0 -18 7 -21 15 -4 8 -10 12 -15 9 -5 -3 -9 0 -9 7 0 7 -3 9 -7 6 -3 -4 -15
0 -25 10 -10 9 -18 13 -18 10 0 -4 -16 5 -36 18 -20 14 -45 25 -55 25 -11 0
-19 7 -19 16 0 12 -6 15 -25 10 -19 -5 -23 -3 -19 9 7 18 -7 20 -25 3 -11 -11
-13 -10 -8 5 6 15 3 17 -13 12 -13 -4 -36 3 -65 19 -24 14 -53 26 -64 26 -12
0 -21 4 -21 10 0 5 -12 13 -27 16 -16 4 -41 13 -57 21 -16 9 -38 16 -50 18
-12 2 -47 12 -78 23 -32 12 -58 18 -58 15 0 -3 -6 0 -13 6 -8 6 -22 12 -33 14
-20 4 -34 7 -162 38 -46 11 -100 22 -120 25 -33 3 -71 9 -112 18 -8 2 -46 6
-85 10 -38 4 -88 10 -110 13 -40 6 -1430 14 -1437 8z m3334 -957 c-7 -7 -12
-8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11 -7 -17z m58 -77 c0 -6 -4 -13 -10 -16
-5 -3 -10 1 -10 9 0 9 5 16 10 16 6 0 10 -4 10 -9z m89 -17 c14 -7 28 -11 33
-8 4 3 8 1 8 -4 0 -8 43 -30 70 -36 3 -1 12 -6 20 -11 8 -5 20 -10 25 -11 6
-1 28 -10 50 -19 22 -10 41 -16 41 -14 1 2 11 -5 23 -15 12 -11 21 -16 21 -12
0 3 23 -5 50 -19 28 -14 50 -22 50 -19 0 4 8 0 18 -9 10 -10 21 -15 24 -12 3
3 13 -5 24 -17 19 -22 19 -22 0 -8 -11 8 -21 13 -23 11 -2 -2 -32 9 -66 25
-34 15 -66 29 -72 30 -5 1 -37 14 -70 30 -33 15 -62 29 -65 30 -35 10 -70 28
-70 36 0 5 -4 7 -8 4 -5 -3 -22 2 -38 10 -16 9 -29 15 -29 14 0 -7 -54 22 -64
34 -12 14 -11 16 5 9 10 -4 30 -13 43 -19z m-1697 -412 c18 -7 46 -46 92 -127
102 -178 246 -332 338 -360 12 -4 40 -16 60 -26 43 -22 149 -41 348 -64 80 -9
156 -18 170 -20 14 -2 50 -6 80 -10 30 -4 66 -8 80 -10 136 -20 260 -27 455
-27 269 -1 366 7 415 33 19 11 40 19 46 19 6 0 17 7 24 15 29 34 38 17 44 -82
4 -54 14 -123 23 -153 9 -30 22 -84 29 -120 13 -69 43 -159 75 -226 10 -22 19
-47 19 -55 0 -8 4 -19 9 -24 9 -9 42 -69 79 -140 54 -104 243 -311 371 -405
34 -25 72 -53 84 -62 69 -55 248 -146 377 -193 41 -15 98 -35 125 -46 28 -10
70 -21 95 -25 25 -4 65 -15 90 -24 25 -9 75 -20 112 -24 37 -3 85 -13 107 -21
22 -7 100 -19 173 -25 73 -5 158 -15 188 -22 37 -7 341 -13 945 -17 l890 -6
-3 -72 c-2 -40 -9 -85 -17 -100 -20 -37 -18 -81 5 -118 10 -16 31 -54 46 -82
14 -29 31 -53 37 -53 6 0 8 -3 4 -6 -3 -3 4 -23 17 -43 29 -46 47 -99 37 -106
-11 -6 -32 20 -71 90 -18 33 -41 74 -51 92 -10 17 -28 51 -39 74 -14 26 -25
38 -31 32 -6 -6 18 -65 69 -164 44 -85 82 -169 86 -188 6 -33 6 -33 -25 -27
-27 5 -30 4 -24 -12 15 -36 11 -47 -17 -45 -28 2 -37 -10 -18 -22 13 -8 -101
-123 -145 -145 -144 -73 -311 -89 -915 -85 -481 4 -643 21 -778 81 -29 13 -57
24 -62 24 -6 0 -10 4 -10 9 0 5 -9 13 -20 16 -11 3 -20 12 -20 18 0 7 -16 23
-36 36 -25 17 -34 30 -31 43 4 14 -1 18 -18 18 -28 0 -42 17 -26 33 20 20 12
29 -20 22 -31 -7 -31 -7 -24 26 4 19 15 46 26 61 10 15 19 31 19 36 0 5 6 15
14 21 7 6 34 42 60 79 25 37 59 86 76 110 16 23 30 47 30 52 0 30 -24 8 -86
-77 -38 -53 -90 -125 -116 -160 -27 -35 -48 -72 -49 -81 0 -14 -2 -13 -9 3 -5
11 -9 25 -9 31 -1 6 51 80 114 164 63 83 115 161 115 173 0 19 -2 19 -15 7
-16 -17 -122 -153 -192 -247 -45 -60 -73 -79 -73 -49 0 8 5 18 10 21 6 3 10
13 10 22 0 9 19 37 43 63 63 71 117 138 117 145 0 3 13 20 30 38 29 31 40 67
20 67 -5 0 -31 -26 -57 -59 -27 -32 -79 -96 -118 -142 -38 -45 -70 -89 -70
-97 0 -9 -6 -17 -12 -19 -9 -3 -12 7 -9 36 4 43 4 44 156 216 44 50 80 97 79
105 0 8 -37 -25 -82 -75 -182 -199 -194 -210 -210 -197 -8 7 -22 9 -32 6 -14
-6 -16 -3 -10 14 5 17 2 22 -12 22 -10 0 -28 7 -39 15 -12 8 -24 12 -27 8 -4
-3 -23 2 -44 12 -24 12 -38 14 -41 7 -2 -7 -17 -6 -45 5 -150 54 -670 57 -809
4 -18 -7 -29 -6 -37 2 -9 9 -14 9 -20 -2 -5 -8 -16 -11 -25 -8 -8 3 -18 2 -21
-4 -4 -5 -12 -6 -18 -2 -19 12 -37 12 -34 0 3 -15 -74 -40 -86 -28 -13 13 0
31 23 31 13 0 17 5 14 14 -3 8 3 19 15 26 19 10 21 19 21 129 0 80 -5 132 -15
161 -8 23 -15 63 -15 89 0 25 -3 57 -7 69 -8 31 -19 29 -203 -37 -80 -29 -190
-67 -244 -86 -112 -39 -169 -46 -96 -12 25 11 51 22 58 23 8 1 21 6 30 10 9 4
26 9 36 11 11 2 25 8 33 14 7 6 13 8 13 5 0 -4 9 -2 21 4 11 6 28 13 37 16 9
2 77 25 150 50 l132 46 0 39 c0 66 18 69 -415 -68 -77 -24 -152 -44 -167 -45
-16 -1 -28 -5 -28 -10 0 -4 -4 -8 -10 -8 -17 0 -11 29 9 39 10 6 33 13 52 16
18 3 39 11 46 17 7 6 13 8 13 5 0 -3 28 4 63 15 34 11 73 22 86 24 14 3 29 8
35 12 6 3 16 7 23 8 7 1 41 11 75 22 35 11 89 28 121 37 45 12 57 20 57 36 0
11 -6 22 -13 25 -7 2 -94 -18 -193 -46 -98 -27 -226 -62 -284 -76 -58 -15
-113 -31 -123 -36 -27 -13 -39 0 -22 25 9 14 26 24 44 25 16 1 47 9 70 17 23
8 41 12 41 10 0 -3 11 0 26 5 14 6 39 12 56 15 17 3 36 9 42 13 6 3 16 7 21 8
6 1 39 9 75 19 36 9 75 19 87 21 12 2 33 8 45 13 13 5 26 9 31 10 36 5 57 16
57 30 0 10 -2 20 -5 23 -3 3 -148 -30 -322 -74 -173 -43 -318 -76 -321 -73 -3
3 0 16 6 29 9 20 7 27 -8 41 -17 16 -19 15 -22 -5 -5 -32 -23 -19 -20 15 5 41
13 47 66 51 25 1 55 5 65 9 11 3 28 8 38 10 10 2 52 11 93 20 41 9 86 18 100
21 14 2 36 7 50 10 35 8 46 11 123 27 65 14 85 22 76 31 -2 2 -121 -21 -264
-50 -143 -30 -282 -57 -310 -60 -27 -4 -54 -10 -59 -15 -15 -14 -36 -11 -36 5
0 7 7 19 15 26 11 9 13 16 5 24 -7 7 -14 6 -22 -2 -16 -16 -52 -16 -45 1 3 6
8 20 12 30 8 20 -14 24 -33 5 -9 -9 -15 -9 -24 0 -9 9 -9 15 0 24 20 20 13 27
-23 21 -34 -5 -48 8 -25 22 22 13 8 23 -25 18 -39 -6 -46 8 -18 39 15 16 15
18 0 18 -9 0 -20 -7 -24 -15 -7 -13 -13 -13 -36 -3 -15 7 -27 16 -27 21 0 4
-18 14 -39 23 -30 12 -38 20 -33 32 5 15 4 15 -11 3 -15 -12 -20 -11 -36 8
-10 11 -26 21 -35 21 -9 0 -16 5 -16 10 0 6 -6 10 -14 10 -7 0 -19 7 -26 15
-19 22 -110 64 -126 58 -10 -4 -12 0 -8 13 6 16 5 17 -7 7 -19 -18 -30 -16
-22 4 5 13 2 15 -11 10 -10 -4 -34 2 -56 13 -22 11 -46 20 -55 20 -8 0 -15 5
-15 10 0 6 -5 10 -10 10 -10 0 -49 16 -155 65 -56 25 -83 35 -169 60 -33 10
-71 24 -85 31 -14 8 -32 14 -38 14 -7 0 -52 12 -100 26 -49 15 -110 32 -138
39 -27 7 -61 17 -75 23 -14 5 -63 17 -110 26 -46 10 -89 20 -94 24 -6 3 -17 7
-26 9 -8 1 -89 18 -180 37 -91 20 -174 37 -185 39 -11 2 -27 6 -35 9 -18 6
-474 98 -560 113 -8 1 -22 5 -30 8 -8 3 -78 17 -155 32 -155 29 -303 60 -380
80 -119 31 -242 67 -272 81 -17 8 -36 14 -42 14 -7 0 -23 7 -36 16 -13 9 -29
13 -35 9 -5 -3 -13 1 -16 9 -3 9 -17 16 -30 16 -13 0 -24 4 -24 9 0 5 -12 12
-26 15 -18 5 -24 11 -20 23 5 11 1 14 -13 10 -13 -3 -18 -1 -14 8 3 8 -8 20
-26 30 -17 8 -31 22 -31 29 0 8 -9 16 -20 19 -14 3 -20 14 -20 32 0 19 -4 26
-13 22 -22 -8 -44 10 -30 24 6 6 9 17 7 24 -7 18 23 40 39 27 12 -10 88 -21
242 -37 33 -3 71 -8 85 -10 14 -2 61 -7 105 -10 44 -3 87 -8 95 -10 8 -2 47
-7 85 -10 39 -3 81 -7 95 -10 14 -2 52 -7 85 -10 33 -3 78 -7 100 -10 22 -2
63 -7 90 -10 28 -3 68 -7 90 -10 22 -3 63 -7 90 -10 28 -3 70 -8 95 -11 41 -6
44 -5 30 9 -14 15 -75 25 -240 42 -30 3 -68 8 -85 10 -16 3 -61 8 -100 11 -96
9 -139 25 -50 18 39 -3 79 -7 90 -9 11 -2 52 -6 90 -10 39 -3 79 -8 90 -10 11
-2 52 -7 90 -10 39 -4 75 -8 80 -9 6 0 12 36 16 84 11 165 96 244 294 270 50
7 95 14 100 15 20 7 721 3 737 -3z m5498 -11 c249 -35 359 -114 421 -301 26
-79 29 -111 34 -320 2 -102 4 -198 4 -214 l1 -28 79 7 78 7 12 -32 c13 -39 10
-46 -30 -54 -17 -3 -45 -12 -62 -20 -75 -34 -114 -36 -798 -37 -640 0 -741 2
-844 16 -22 3 -62 7 -90 10 -50 5 -97 21 -168 56 -22 11 -45 19 -53 19 -8 0
-14 6 -14 13 0 6 -14 23 -30 36 -16 13 -27 27 -24 32 3 5 -4 9 -15 9 -25 0
-37 18 -22 33 19 19 12 29 -13 23 -33 -8 -41 8 -20 39 15 24 30 26 66 11 9 -4
22 -9 30 -10 7 -2 20 -6 28 -9 55 -23 69 -26 64 -13 -3 7 -27 22 -54 34 -28
12 -50 24 -50 28 0 3 3 3 8 1 13 -7 47 -20 60 -23 16 -3 15 27 -3 62 -11 22
-15 64 -15 162 0 166 24 250 94 331 54 61 181 114 301 126 44 4 116 11 160 15
120 11 767 4 865 -9z m-7960 -216 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8
5 15 10 15 6 0 10 -7 10 -15z m185 -10 c33 -3 71 -7 85 -10 14 -2 52 -7 85
-10 33 -3 76 -8 95 -10 19 -3 58 -7 85 -10 28 -3 64 -8 80 -10 17 -2 62 -7
100 -10 39 -4 75 -11 80 -17 7 -6 -2 -8 -25 -5 -19 3 -57 8 -85 11 -102 11
-145 16 -365 42 -124 14 -235 26 -247 25 -12 0 -25 4 -29 11 -5 7 6 9 37 4 24
-3 71 -8 104 -11z m6259 -414 c33 -13 39 -26 11 -26 -13 0 -23 5 -22 10 1 6
-15 10 -35 9 -37 -1 -46 5 -31 20 8 7 34 3 77 -13z m-64 -36 c0 -8 -4 -15 -10
-15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m2051 -1762 c13 -16
12 -17 -3 -4 -17 13 -22 21 -14 21 2 0 10 -8 17 -17z m-3631 -187 c0 -3 -4 -8
-10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-4990 -136 c0
-5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z"/>
<path d="M10420 15720 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
<path d="M11279 15626 c-2 -2 -24 -7 -49 -11 -25 -4 -121 -22 -213 -41 -93
-18 -190 -37 -215 -40 -25 -3 -55 -10 -67 -15 -36 -16 68 -2 253 35 185 37
191 39 260 51 23 4 45 12 48 16 5 9 -9 13 -17 5z"/>
<path d="M11318 15505 c-27 -6 -48 -10 -48 -9 0 2 -11 -1 -26 -6 -14 -6 -39
-12 -57 -16 -18 -3 -36 -10 -42 -15 -13 -14 65 -1 83 14 6 5 12 7 12 4 0 -3
19 0 43 5 23 5 53 12 67 14 29 6 35 10 23 17 -4 2 -29 -1 -55 -8z"/>
<path d="M11085 15453 c-11 -2 -27 -7 -35 -10 -8 -3 -26 -8 -40 -10 -14 -2
-32 -6 -40 -10 -8 -3 -24 -7 -36 -8 -12 -1 -30 -5 -40 -10 -10 -5 -27 -9 -36
-10 -10 -2 -22 -3 -28 -4 -5 0 -10 -5 -10 -10 0 -5 18 -5 43 1 23 5 52 12 64
14 12 2 34 9 48 14 14 5 25 8 25 5 0 -2 15 1 33 6 17 6 43 12 57 15 28 6 53
25 30 23 -8 -1 -24 -3 -35 -6z"/>
<path d="M13028 14921 c-9 -13 -71 -79 -137 -147 -66 -68 -116 -126 -111 -129
7 -5 48 34 95 90 6 6 48 50 94 97 46 47 81 92 79 99 -3 9 -10 5 -20 -10z"/>
<path d="M12740 14710 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z"/>
<path d="M18270 18670 c0 -3 -29 -5 -65 -3 -61 4 -167 -22 -242 -59 -11 -6
-26 -8 -31 -4 -6 3 -13 2 -16 -2 -3 -5 -28 -8 -55 -6 -46 2 -50 0 -48 -19 2
-18 -3 -22 -23 -21 -32 2 -40 -12 -34 -55 5 -30 4 -33 -15 -28 -12 3 -27 -3
-38 -15 -10 -11 -63 -50 -118 -86 -415 -271 -464 -306 -475 -335 -13 -39 -14
-4258 -1 -4305 26 -87 93 -166 286 -334 61 -52 188 -165 283 -249 208 -184
272 -230 373 -264 l76 -25 1324 0 c1113 0 1342 3 1434 15 61 8 171 18 245 21
74 3 171 14 215 24 44 10 118 21 165 25 47 3 103 13 125 20 22 8 74 21 115 29
91 18 190 42 250 63 25 8 66 20 91 26 25 7 53 17 61 24 8 7 39 18 69 24 30 6
61 17 70 25 8 8 30 17 47 20 17 4 37 13 43 20 6 8 30 19 53 25 22 6 48 17 56
24 8 6 30 17 48 23 18 6 45 20 59 32 14 11 31 20 38 20 6 0 20 9 30 20 10 11
23 20 29 20 5 0 27 11 48 25 21 14 57 37 81 52 205 133 508 450 605 635 18 35
44 81 57 103 38 65 95 183 95 198 0 8 8 25 18 38 11 13 22 39 26 58 3 19 13
42 21 51 9 10 20 43 25 75 5 32 16 67 23 79 8 11 17 42 21 67 4 25 15 68 25
95 10 27 21 82 25 122 3 41 14 88 22 105 12 21 19 76 25 176 4 80 14 171 21
201 19 80 18 687 -1 750 -7 25 -16 116 -20 203 -5 115 -10 160 -20 166 -16 10
-30 77 -31 148 0 31 -8 68 -19 90 -11 21 -23 64 -26 96 -5 35 -12 57 -20 57
-14 0 -25 43 -25 95 0 47 -9 68 -25 55 -8 -7 -12 -6 -13 3 -5 78 -16 117 -32
117 -13 0 -19 12 -24 49 -5 42 -9 48 -26 44 -16 -4 -19 1 -22 43 -2 41 -6 49
-23 49 -15 0 -20 7 -21 32 -3 50 -10 62 -34 56 -18 -5 -21 -2 -19 18 5 51 -1
70 -18 67 -14 -2 -19 5 -21 30 -3 29 -6 31 -29 26 -23 -6 -25 -4 -21 17 3 13
2 35 -2 49 l-8 25 -7 -22 c-15 -47 -32 -18 -26 45 2 24 -16 30 -25 8 -5 -12
-9 -12 -25 2 -12 11 -15 20 -9 26 21 21 11 36 -21 29 -28 -5 -30 -4 -23 21 6
26 -8 37 -21 16 -4 -7 -14 -2 -26 11 -10 12 -19 18 -19 13 0 -5 -8 1 -18 13
-27 33 -112 94 -123 88 -5 -4 -8 -1 -7 6 2 7 -7 18 -19 24 -13 5 -23 13 -23
17 0 4 -7 5 -15 2 -8 -4 -15 -1 -15 5 0 6 -6 11 -14 11 -8 0 -16 6 -19 14 -3
8 -21 22 -40 31 -20 9 -43 25 -53 36 -9 10 -21 19 -27 19 -5 0 -21 8 -35 19
-15 10 -53 33 -84 50 -32 17 -84 45 -115 63 -100 54 -108 58 -121 58 -8 0 -26
6 -40 14 -24 12 -79 37 -92 41 -7 2 -34 12 -95 35 -27 10 -63 21 -80 25 -16 4
-43 13 -60 20 -30 14 -107 37 -135 41 -20 3 -146 33 -186 45 -17 5 -40 9 -52
9 -11 0 -24 5 -27 11 -4 6 -13 8 -20 5 -7 -2 -32 0 -56 6 -40 9 -56 12 -129
23 -78 12 -151 19 -197 20 -29 0 -53 3 -53 6 0 3 -17 6 -37 7 -21 1 -69 4
-108 8 -38 3 -103 7 -142 7 -40 1 -73 6 -73 10 0 4 -10 2 -22 -4 -12 -7 -23
-8 -25 -3 -2 5 -495 8 -1180 6 -648 -2 -1211 1 -1250 6 -40 4 -73 6 -73 2z
m1482 -1459 c64 -5 126 -14 137 -20 11 -6 46 -15 78 -20 32 -5 74 -14 93 -21
118 -42 177 -65 190 -75 8 -6 26 -17 40 -23 81 -41 190 -140 246 -226 15 -23
35 -53 46 -68 10 -15 18 -30 18 -34 0 -4 8 -25 19 -48 31 -69 52 -138 62 -199
5 -32 13 -66 18 -75 4 -9 11 -37 14 -62 7 -52 22 -393 22 -490 -1 -66 -15
-447 -18 -465 -1 -5 8 -9 21 -9 18 1 20 -1 11 -12 -6 -8 -16 -11 -22 -7 -6 4
-7 1 -2 -6 5 -9 10 -10 18 -2 7 7 15 7 26 -2 10 -8 13 -8 8 -1 -5 9 9 11 51 9
43 -1 56 -6 54 -16 -3 -11 -1 -11 9 -2 7 7 19 9 34 3 13 -5 26 -6 30 -2 4 4 5
1 3 -7 -1 -7 5 -15 15 -16 13 -3 15 0 8 9 -8 9 1 12 37 10 26 -1 48 -3 50 -4
1 -2 7 -4 12 -5 6 -1 13 -4 18 -5 4 -1 11 -2 15 -1 5 1 6 -3 2 -9 -3 -6 -12
-7 -18 -3 -7 4 -22 8 -35 9 -16 1 -20 -2 -12 -10 15 -15 102 -16 90 0 -13 15
75 11 94 -5 7 -6 17 -7 21 -3 5 4 5 1 1 -6 -4 -7 -13 -9 -19 -5 -20 13 -34 11
-34 -3 0 -9 4 -11 12 -4 7 5 15 7 19 3 10 -10 96 -11 90 -2 -2 4 -13 6 -24 4
-11 -2 -22 0 -24 4 -3 4 11 7 31 6 26 0 37 -6 39 -17 3 -21 -21 -42 -37 -33
-6 4 -36 9 -67 11 -30 2 -62 10 -70 16 -13 11 -14 11 -7 -1 5 -9 4 -12 -3 -7
-6 4 -28 9 -49 11 -55 7 -66 10 -82 19 -10 7 -12 6 -6 -4 6 -9 4 -11 -6 -4 -8
4 -35 9 -61 10 -26 0 -54 6 -62 13 -11 9 -15 9 -18 0 -3 -8 -9 -9 -18 -4 -8 5
-41 10 -73 11 -32 2 -65 8 -72 14 -11 9 -15 7 -21 -14 -3 -14 -7 -39 -7 -57
-2 -34 -1 -35 78 -44 22 -2 54 -8 70 -11 17 -4 51 -8 76 -9 25 -1 50 -5 56 -8
5 -3 31 -8 59 -11 27 -2 56 -7 65 -11 8 -3 38 -7 65 -9 27 -2 62 -7 77 -11 15
-4 47 -9 70 -10 23 -2 42 -4 42 -5 0 -3 42 -10 86 -14 26 -2 51 -6 58 -10 6
-4 37 -8 70 -10 32 -2 63 -6 68 -9 5 -3 35 -8 68 -12 32 -3 72 -8 89 -11 17
-3 42 -7 56 -9 14 -1 35 -5 48 -9 12 -4 42 -9 67 -10 25 -2 49 -6 52 -10 3 -3
12 -1 19 5 9 8 15 5 23 -9 12 -22 5 -33 -14 -26 -8 3 -20 -6 -28 -22 -13 -24
-13 -34 -1 -67 17 -47 13 -56 -21 -49 -22 4 -25 1 -25 -22 0 -15 4 -36 10 -46
12 -23 -13 -50 -26 -28 -13 19 -23 -43 -11 -66 11 -22 -8 -39 -35 -30 -21 6
-24 -6 -6 -24 9 -9 10 -15 2 -20 -6 -4 -14 -17 -18 -29 -4 -13 -12 -23 -17
-23 -5 0 -9 -8 -9 -18 0 -10 -8 -25 -17 -33 -10 -9 -25 -26 -33 -39 -8 -12
-23 -33 -33 -45 -23 -31 -134 -114 -174 -131 -17 -8 -34 -18 -38 -24 -3 -5
-26 -19 -49 -30 -65 -29 -283 -100 -310 -100 -12 0 -26 -3 -29 -6 -4 -4 -19
-8 -34 -10 -16 -1 -66 -9 -113 -18 -220 -39 -419 -50 -960 -50 -384 -1 -419 0
-475 18 -33 11 -77 30 -97 43 -21 13 -45 23 -53 23 -24 0 -27 21 -13 76 9 33
12 95 9 180 -2 71 -5 736 -7 1478 l-4 1349 107 -7 c60 -3 161 -10 225 -15z
m1622 -1942 c-3 -5 -10 -7 -15 -3 -5 3 -7 10 -3 15 3 5 10 7 15 3 5 -3 7 -10
3 -15z m109 6 c20 1 37 -2 37 -7 0 -4 -5 -8 -11 -8 -5 0 -8 -4 -5 -9 9 -15
-22 -34 -41 -25 -17 7 -39 10 -99 13 -14 0 -23 5 -19 10 3 6 15 8 27 6 11 -3
18 -2 14 2 -13 13 16 32 37 24 12 -4 39 -7 60 -6z m91 -17 c4 4 6 1 4 -7 -2
-9 6 -15 24 -16 15 -1 25 2 22 7 -3 4 -9 6 -14 3 -5 -3 -11 -2 -14 3 -7 12 79
8 95 -4 8 -6 22 -10 31 -9 10 2 16 -2 15 -8 -1 -6 9 -11 23 -12 14 0 24 -6 23
-11 -1 -6 5 -11 13 -11 12 0 19 16 15 39 -1 9 25 4 40 -9 8 -6 21 -9 30 -8 9
2 16 -3 16 -11 0 -8 -4 -10 -11 -5 -6 5 -19 7 -29 6 -9 -2 -15 1 -11 6 3 5 -3
9 -12 9 -16 0 -16 -1 1 -20 13 -14 21 -17 31 -9 8 7 14 7 19 -1 8 -13 -13 -21
-40 -16 -11 3 -35 5 -53 5 -18 1 -31 4 -29 9 3 4 -20 8 -51 9 -101 5 -107 5
-117 11 -5 4 -22 7 -37 8 -54 3 -59 4 -53 14 4 6 12 7 18 4 7 -4 9 -4 5 1 -13
13 13 37 27 25 6 -5 15 -6 19 -2z m226 -36 c0 -12 -3 -12 -15 -2 -8 7 -15 13
-15 15 0 1 7 2 15 2 8 0 15 -7 15 -15z m165 -31 c-2 -10 3 -18 10 -18 8 0 12
4 10 8 -3 3 2 12 9 18 10 8 16 9 21 1 3 -6 11 -7 17 -4 6 4 8 2 4 -4 -3 -6 -2
-13 4 -17 13 -8 3 -25 -15 -25 -8 0 -12 8 -10 20 2 11 -1 22 -6 26 -7 4 -9 -3
-7 -17 3 -21 0 -24 -27 -20 -42 4 -49 11 -31 30 9 10 13 21 9 26 -5 4 -3 5 4
1 6 -3 10 -15 8 -25z m-35 -6 c-5 -7 -7 -15 -3 -18 3 -4 0 -7 -9 -7 -8 0 -18
3 -21 7 -10 9 10 30 28 30 10 0 12 -4 5 -12z"/>
<path d="M20806 15342 c-3 -5 1 -9 9 -9 8 0 12 4 9 9 -3 4 -7 8 -9 8 -2 0 -6
-4 -9 -8z"/>
<path d="M20846 15342 c-3 -5 1 -9 9 -9 8 0 12 4 9 9 -3 4 -7 8 -9 8 -2 0 -6
-4 -9 -8z"/>
<path d="M20900 15327 c0 -8 7 -14 15 -14 11 0 12 4 4 14 -6 7 -13 13 -15 13
-2 0 -4 -6 -4 -13z"/>
<path d="M21166 15302 c-3 -5 1 -9 9 -9 8 0 12 4 9 9 -3 4 -7 8 -9 8 -2 0 -6
-4 -9 -8z"/>
<path d="M20650 15151 c0 -12 3 -21 8 -22 4 0 18 -3 32 -6 14 -3 43 -6 65 -7
22 0 42 -4 45 -7 3 -4 32 -10 65 -14 32 -3 63 -9 67 -11 4 -3 22 -6 40 -8 18
-2 47 -6 63 -9 17 -4 47 -9 69 -12 21 -3 41 -7 45 -10 4 -2 33 -6 65 -9 32 -4
62 -9 67 -12 5 -3 33 -7 62 -10 50 -4 92 -11 92 -14 0 -1 16 -3 35 -4 19 -2
44 -6 55 -9 11 -4 44 -9 74 -12 29 -3 58 -8 65 -12 6 -4 29 -8 51 -8 22 -1 56
-5 75 -10 19 -4 51 -9 71 -10 20 -1 42 -6 50 -10 24 -15 58 -11 62 7 7 26 -6
33 -88 42 -22 3 -40 5 -40 6 0 2 -36 8 -85 15 -25 3 -52 8 -60 10 -8 3 -35 7
-60 10 -25 3 -58 7 -75 10 -16 3 -43 7 -60 9 -16 2 -37 6 -45 9 -14 5 -125 23
-200 32 -19 2 -46 7 -60 10 -14 2 -38 7 -55 9 -16 2 -52 8 -80 12 -27 4 -64 8
-81 9 -17 1 -35 4 -40 7 -5 3 -31 9 -59 12 -27 4 -57 9 -65 11 -8 2 -33 6 -55
9 -22 3 -57 9 -77 12 -34 5 -38 3 -38 -15z"/>
<path d="M21840 14611 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5
0 -10 -4 -10 -9z"/>
<path d="M21408 15270 c13 -15 65 -23 57 -9 -3 4 -13 6 -23 4 -9 -1 -24 2 -32
8 -12 9 -13 9 -2 -3z"/>
<path d="M21555 15240 c10 -11 20 -20 23 -20 3 0 -3 9 -13 20 -10 11 -20 20
-23 20 -3 0 3 -9 13 -20z"/>
<path d="M21647 15243 c-12 -11 -8 -30 7 -30 7 0 13 6 12 13 -3 18 -11 25 -19
17z"/>
<path d="M21680 15227 c0 -8 7 -14 15 -14 11 0 12 4 4 14 -6 7 -13 13 -15 13
-2 0 -4 -6 -4 -13z"/>
<path d="M10145 18360 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5
0 -13 -4 -16 -10z"/>
<path d="M10285 18270 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5
0 -13 -4 -16 -10z"/>
<path d="M10325 18221 c-6 -11 9 -23 19 -14 9 9 7 23 -3 23 -6 0 -12 -4 -16
-9z"/>
<path d="M10680 17956 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21
-21 13z"/>
<path d="M10760 17911 c0 -5 7 -12 16 -15 14 -5 15 -4 4 9 -14 17 -20 19 -20
6z"/>
<path d="M24525 17905 c-188 -21 -416 -63 -433 -80 -7 -7 -7 -995 0 -995 4 0
31 9 60 20 213 79 568 127 858 117 253 -10 469 -43 705 -107 44 -12 94 -25
110 -30 135 -36 376 -131 500 -197 17 -9 64 -33 105 -54 150 -75 491 -296 585
-379 22 -20 58 -49 79 -65 46 -34 307 -293 389 -387 185 -211 379 -514 481
-752 88 -205 161 -436 181 -577 4 -25 10 -51 14 -57 5 -8 132 38 412 149 496
197 501 199 495 216 -3 8 -13 42 -22 76 -77 300 -247 704 -399 947 -167 267
-296 440 -495 665 -122 137 -409 409 -490 464 -19 13 -51 39 -71 58 -20 19
-63 53 -97 76 -33 23 -105 74 -159 113 -186 135 -590 357 -838 460 -99 41
-417 151 -535 184 -138 40 -434 101 -585 122 -190 26 -664 33 -850 13z"/>
<path d="M23135 17900 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z"/>
<path d="M10800 17896 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21
-21 13z"/>
<path d="M10840 17871 c0 -5 7 -12 16 -15 14 -5 15 -4 4 9 -14 17 -20 19 -20
6z"/>
<path d="M10920 17831 c0 -5 7 -12 16 -15 14 -5 15 -4 4 9 -14 17 -20 19 -20
6z"/>
<path d="M10960 17816 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21
-21 13z"/>
<path d="M11000 17796 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21
-21 13z"/>
<path d="M11040 17776 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21
-21 13z"/>
<path d="M11080 17756 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21
-21 13z"/>
<path d="M11120 17736 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21
-21 13z"/>
<path d="M11160 17716 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21
-21 13z"/>
<path d="M24250 15887 c-47 -4 -102 -10 -124 -15 l-38 -7 0 -418 c0 -231 2
-420 4 -422 2 -2 35 1 73 5 70 9 358 5 424 -5 112 -17 223 -38 271 -52 141
-41 248 -77 280 -93 19 -9 69 -32 110 -51 119 -54 325 -184 434 -274 72 -60
223 -211 291 -291 124 -148 232 -328 321 -538 20 -44 39 -85 43 -89 6 -6 758
282 779 298 1 1 -18 60 -44 131 -90 250 -217 489 -373 704 -168 231 -402 456
-667 640 -93 65 -274 175 -318 194 -17 8 -67 31 -111 52 -186 88 -504 184
-695 209 -230 30 -476 39 -660 22z"/>
<path d="M7713 14963 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M7620 14950 c-23 -7 -23 -8 -3 -9 12 -1 25 4 28 9 3 6 5 10 3 9 -2
-1 -14 -5 -28 -9z"/>
<path d="M7520 14933 c-14 -3 -29 -8 -34 -13 -4 -5 11 -4 35 1 67 15 66 24 -1
12z"/>
<path d="M7735 14920 c-14 -7 -8 -8 20 -3 22 3 42 7 44 9 9 8 -45 2 -64 -6z"/>
<path d="M7448 14913 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7617 14900 c-48 -10 -91 -21 -95 -25 -7 -7 14 -4 113 16 33 6 64 16
70 20 5 5 7 8 5 8 -3 -1 -45 -9 -93 -19z"/>
<path d="M7398 14903 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7270 14884 l-55 -13 50 5 c28 3 54 10 60 15 5 5 7 8 5 8 -3 -1 -30
-7 -60 -15z"/>
<path d="M7348 14893 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7465 14870 c-22 -5 -56 -11 -75 -15 -19 -3 -39 -10 -43 -14 -5 -5
28 -1 72 8 44 9 83 19 87 24 4 4 5 7 3 6 -2 -1 -22 -5 -44 -9z"/>
<path d="M7160 14859 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z"/>
<path d="M7128 14853 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7068 14843 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M6980 14830 c-23 -7 -23 -8 -3 -9 12 -1 25 4 28 9 3 6 5 10 3 9 -2
-1 -14 -5 -28 -9z"/>
<path d="M7028 14833 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7225 14821 c-3 -6 6 -8 22 -5 37 7 42 14 10 14 -14 0 -28 -4 -32 -9z"/>
<path d="M6908 14813 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6846 14801 c-4 -5 3 -7 14 -4 23 6 26 13 6 13 -8 0 -17 -4 -20 -9z"/>
<path d="M7126 14801 c-4 -5 1 -8 9 -7 21 3 31 16 12 16 -9 0 -18 -4 -21 -9z"/>
<path d="M6726 14782 c-2 -4 11 -6 30 -4 19 2 34 6 34 8 0 8 -59 4 -64 -4z"/>
<path d="M7040 14779 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z"/>
<path d="M6660 14773 c-14 -3 -29 -8 -34 -13 -4 -5 11 -4 35 1 67 15 66 24 -1
12z"/>
<path d="M6965 14770 c-16 -7 -17 -9 -3 -9 9 -1 20 4 23 9 7 11 7 11 -20 0z"/>
<path d="M6311 14756 c-16 -19 -11 -29 8 -17 7 5 12 14 9 21 -3 9 -8 7 -17 -4z"/>
<path d="M6588 14753 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6538 14743 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6840 14739 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z"/>
<path d="M6455 14730 c-8 -13 5 -13 25 0 13 8 13 10 -2 10 -9 0 -20 -4 -23
-10z"/>
<path d="M6788 14733 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6726 14721 c-4 -5 3 -7 14 -4 23 6 26 13 6 13 -8 0 -17 -4 -20 -9z"/>
<path d="M6688 14713 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6635 14700 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z"/>
<path d="M6588 14693 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6506 14682 c-3 -5 1 -9 9 -9 8 0 15 4 15 9 0 4 -4 8 -9 8 -6 0 -12
-4 -15 -8z"/>
<path d="M6590 14379 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z"/>
<path d="M24460 14000 c-72 -10 -176 -49 -245 -91 -105 -63 -220 -194 -260
-295 -9 -21 -20 -48 -25 -59 -28 -64 -43 -212 -31 -302 30 -204 128 -359 298
-471 118 -77 197 -104 323 -109 128 -6 224 12 325 61 254 121 400 370 382 649
-12 179 -67 298 -197 428 -93 92 -163 134 -290 171 -75 21 -201 30 -280 18z"/>
<path d="M8843 12310 c-166 -21 -309 -70 -443 -150 -275 -165 -456 -413 -548
-748 l-26 -97 -4 -732 -3 -733 300 0 301 0 2 273 3 272 545 0 545 0 3 -272 2
-273 295 0 295 0 0 688 c0 560 -3 704 -15 779 -63 389 -298 715 -641 886 -178
89 -418 131 -611 107z m222 -595 c158 -34 293 -139 372 -291 55 -106 74 -184
76 -318 l2 -111 -547 -3 -548 -2 0 89 c0 202 60 361 183 486 90 91 165 130
302 159 41 8 92 5 160 -9z"/>
<path d="M21781 12309 c-358 -42 -681 -264 -869 -599 -46 -83 -99 -228 -124
-343 -22 -100 -23 -114 -23 -807 l0 -705 303 -3 302 -2 0 275 0 275 545 0 545
0 0 -275 0 -275 300 0 300 0 0 653 c0 362 -5 694 -10 747 -30 285 -159 550
-361 745 -195 188 -400 285 -663 314 -107 12 -138 12 -245 0z m232 -594 c158
-33 298 -147 376 -307 51 -102 71 -193 71 -320 l0 -98 -545 0 -545 0 0 108 c0
126 18 210 65 307 78 161 216 274 380 310 79 18 116 18 198 0z"/>
<path d="M25805 12310 c-443 -57 -809 -363 -955 -798 -59 -175 -60 -198 -60
-963 l0 -699 300 0 300 0 2 273 3 272 542 3 543 2 2 -272 3 -273 298 -3 298
-2 -4 722 c-4 786 -2 757 -63 943 -110 335 -370 610 -689 730 -150 57 -368 84
-520 65z m290 -612 c83 -27 143 -68 219 -148 114 -122 166 -261 166 -452 l0
-108 -545 0 -545 0 0 103 c0 257 125 479 324 577 128 63 249 72 381 28z"/>
<path d="M5389 12213 c11 -27 186 -471 391 -988 460 -1163 445 -1127 492
-1201 66 -106 168 -187 270 -214 76 -20 194 -9 264 25 79 38 179 140 226 230
20 39 144 356 276 705 132 349 313 826 401 1059 89 233 161 426 161 428 0 1
-143 3 -318 3 l-318 0 -274 -717 c-151 -395 -285 -747 -298 -782 -17 -42 -27
-59 -32 -50 -6 10 -528 1332 -607 1537 -4 9 -79 12 -329 12 l-324 0 19 -47z"/>
<path d="M10272 11348 l4 -913 26 -78 c75 -222 252 -400 475 -475 l78 -27 643
-3 642 -3 0 300 0 301 -599 2 c-583 3 -600 4 -621 23 -51 45 -50 24 -50 935
l0 850 -300 0 -301 0 3 -912z"/>
<path d="M15745 12249 c-227 -29 -478 -160 -649 -339 -295 -309 -408 -745
-301 -1165 95 -374 379 -699 721 -824 176 -64 174 -64 807 -68 l577 -4 0 300
0 301 -532 0 c-576 0 -583 1 -695 55 -30 15 -81 52 -115 83 -63 59 -131 149
-122 164 3 4 232 8 510 8 l504 0 0 295 0 295 -504 0 c-278 0 -507 4 -510 8 -8
14 57 103 115 158 65 61 123 96 206 123 60 20 82 21 603 21 l540 0 0 300 0
300 -542 -1 c-299 -1 -574 -6 -613 -10z"/>
<path d="M18310 11056 l0 -1206 613 0 c672 0 719 3 889 60 388 130 682 472
774 900 24 113 25 349 1 472 -85 430 -378 783 -762 915 -167 57 -149 56 -857
60 l-658 4 0 -1205z m1270 593 c195 -45 370 -238 416 -459 70 -331 -129 -664
-434 -729 -32 -7 -168 -11 -347 -11 l-295 0 0 605 0 605 308 0 c175 0 326 -5
352 -11z"/>
<path d="M23080 11965 l0 -295 270 0 270 0 0 -910 0 -910 295 0 295 0 2 908 3
907 273 3 272 2 0 295 0 295 -840 0 -840 0 0 -295z"/>
<path d="M12307 12243 c-11 -10 -8 -1226 3 -1350 26 -293 133 -537 327 -743
243 -258 575 -386 912 -351 267 28 489 136 683 332 149 151 257 342 316 557
l27 97 3 733 3 732 -296 0 -295 0 0 -662 c0 -745 0 -743 -71 -887 -92 -187
-279 -311 -471 -311 -232 0 -429 151 -515 397 l-28 78 -3 693 -3 692 -293 0
c-161 0 -296 -3 -299 -7z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
